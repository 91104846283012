/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initTabs();
        import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
            $('.slick-carousel').slick({
                rows: 0
            });
            $('.skip-slider').slick({
                autoplay: false,
                arrows: true,
                dots: true,
                speed: 1500,
                autoplaySpeed: 5000,
                rows: 0,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: false
                        }
                    },
                ]
            });
            $('.gallery-slider').slick({
                autoplay: true,
                arrows: true,
                dots: false,
                fade: true,
                speed: 1500,
                autoplaySpeed: 5000,
                rows: 0,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1
            });
            $('.testimonial-slider').slick({
                autoplay: true,
                arrows: false,
                dots: false,
                speed: 1500,
                autoplaySpeed: 5000,
                rows: 0,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true
                        }
                    },
                ]
            });
        });
        $('body').css('opacity', 1);

        console.log('App initialized');
    }

    initTabs()
    {
        // change tab on page load
        const hash = location.hash.replace(/^#/, '');
        if (hash) {
            // TODO: this doesn't work
            $(`[data-bs-target="#${hash}"]`, '.nav-tabs').click();
        }

        // change tab outside of tab bar
        $('.clickTab').click(function(e){
            const target = $(e.target).data('bs-target');
            $(`[data-bs-target="${target}"]`, '.nav-tabs').click();
        });

        // update url on tab change
        $('[data-bs-toggle="tab"]').click(function (e) {
            window.location.hash = $(e.target).data('bs-target');
        });
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    addToast(message, type = "success", autoHide = true)
    {
        let _class = "toast align-items-center fs-6 border-0 mt-2 bg-" + type;
        let toast = $('<div class="' + _class + '" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="' + (autoHide ? 'true' : 'false') + '"><div class="d-flex"><div class="toast-body">' + message + '</div><button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div></div>');
        toast.appendTo($('#global-messages'));
        this.initToasts(toast);
    }
}

export default App;
